import React from 'react';
import { ColorTheme, FontTheme, RadiusTheme } from 'types/theme';
interface ButtonStyle {
  text: string;
  type?: 'outline' | 'solid' | 'text';
  color?: ColorTheme;
  fontColor?: ColorTheme;
  borderWidth?: number;
  borderRadius?: RadiusTheme;
  fontTheme?: FontTheme;
}

export interface ButtonProps {
  style: ButtonStyle;
  onClick?: React.MouseEventHandler<HTMLDivElement> | undefined;
  className?: string;
}

const Button: React.FC<ButtonProps> = ({ style, onClick, className = '' }) => {
  const { text, type = 'solid', color = 'primary-700', borderWidth = 1, borderRadius = 'lg', fontTheme = 'title3', fontColor = 'white' } = style;
  const defaultClass = `flex flex-row item-center justify-center py-5 text-${fontTheme} flex-1 max-h-[72px]`;
  const radiusClass = `rounded-${borderRadius}`;
  const borderClass = `border-solid border-${borderWidth} border-${color}`;

  // solid button
  if (type === 'solid')
    return (
      <div className={`${defaultClass} bg-${color} ${radiusClass} text-${fontColor.toString()} ${className}`} onClick={onClick}>
        {text}
      </div>
    );

  if (type === 'text') {
    return (
      <div className={`${defaultClass} text-${color} ${className} text-${fontColor.toString()}`} onClick={onClick}>
        {text}
      </div>
    );
  }

  // outline button
  return (
    <div className={`${defaultClass} ${borderClass} ${radiusClass} bg-white text-${color} ${className}`} onClick={onClick}>
      {text}
    </div>
  );
};

export default Button;
