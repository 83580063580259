import { useEffect, useRef } from 'react';

interface PageLayoutProps {
  children: React.ReactNode;
  overlay?: boolean; // modal, bottomsheet prop
  opacity?: number;
  onClick?: () => void;
}

const PageLayout: React.FC<PageLayoutProps> = ({ children, overlay = false, opacity = 0.5, onClick }) => {
  const ref = useRef<HTMLDivElement>(null);
  const appBarPadding = 16;
  const notchHeight = MainUI.getNotchValue().statusHeight;
  let statusHeight = notchHeight ? notchHeight + appBarPadding : 0;

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    if (BResource.getSelectedLocale() === 'ar') {
      ref.current.setAttribute('dir', 'rtl');
    }

    if (!overlay) {
      ref.current.style.height = `calc(100vh - ${statusHeight}px)`;
      ref.current.style.top = `${statusHeight}px`;
    }
  }, [statusHeight, overlay]);

  if (overlay)
    return (
      <div
        ref={ref}
        className={`absolute flex items-center w-screen h-screen overlay`}
        style={{ backgroundColor: `rgba(0,0,0,${opacity.toString()})` }}
        onClick={(e: any) => {
          if (e.target.classList.contains('overlay') && onClick) onClick();
        }}
      >
        <div style={{ top: `${-statusHeight}px`, height: `${statusHeight}px` }} className={`w-screen bg-transparent absolute`}></div>
        {children}
      </div>
    );

  return (
    <div ref={ref} className={`absolute block w-screen h-screen bg-gray-100`}>
      <div style={{ top: `${-statusHeight}px`, height: `${statusHeight}px` }} className={`w-screen bg-gray-100 absolute`}></div>
      {children}
    </div>
  );
};

export default PageLayout;
