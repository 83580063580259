import { Body, PageLayout } from 'components/common';
import Icon from 'components/common/Icon';
import { useEffect, useState } from 'react';
import { Product } from 'types/model';

const TimeBuffPurchaseModal: React.FC = () => {
  const [selectedIdx, setSelectedIdx] = useState<number>();
  const [open, setOpen] = useState<boolean>();
  const [products, setProducts] = useState<Product[]>([]);

  const handleClickBundle = (product: Product, idx: number) => {
    setSelectedIdx(idx);
    PurchaseManager.buyCoin(product.productId);
  };

  useEffect(() => {
    setOpen(true);
    setProducts(TimeBuffManager.getTimeBuffProducts() ?? []);
  }, []);

  const hide = () => {
    setOpen(false);
    setTimeout(() => {
      Transition.back();
    }, 500);
  };

  return (
    <PageLayout overlay>
      <Body
        overlay
        onClose={hide}
        className="rounded-t-lg"
        backgroundColor="rgba(255, 67, 144, 0.50)"
        style={{ backdropFilter: 'blur(16px)', WebkitBackdropFilter: 'blur(16px)', transition: 'all 0.3s ease-in-out', transform: open ? 'unset' : 'translateY(100%)' }}
      >
        <Icon name="cafe/close.svg" className="absolute top-2 left-3 z-10" onClick={hide} />
        <div className="py-8 px-12" style={{ background: ' linear-gradient(116deg, #FFADCF 1.5%, #FF4390 50.75%, #FF4390 100%)' }}>
          <img className="w-full" src="/imgweb/sale/infinity_silver.png" alt="" />
        </div>
        <div className="px-8 py-4 text-title3 text-white text-center">{brsc.time_buff_sub_1.replace('$param', TimeBuffManager.BUFF_DURATION / (60 * 1000))}</div>
        <div className="flex flex-column align-center px-4 w-full">
          {products.map((product, index) => (
            <TimeBuffBundleItem
              key={`time-buff-bundle-${product.productId}`}
              onClick={() => handleClickBundle(product, index)}
              product={product}
              selected={index === selectedIdx}
            />
          ))}
        </div>
        <div className="flex py-6 px-4 flex-column items-start justify-center text-center text-white gap-2">
          <div className="text-title3 pl-2">Check point☝️</div>
          <ul className="text-4 text-start py-0 px-4 m-0">
            <li>{brsc.time_buff_sub_3.replace('$param', TimeBuffManager.BUFF_DURATION / (1000 * 60))}</li>
            <li>{brsc.time_buff_sub_4}</li>
            <li>{brsc.time_buff_sub_5.replace('$param', 14)}</li>
          </ul>
          <div className="h-12" />
        </div>
      </Body>
    </PageLayout>
  );
};

interface TimeBuffBundleItemProps {
  selected?: boolean;
  product: Product;
  onClick: () => void;
}

const TimeBuffBundleItem: React.FC<TimeBuffBundleItemProps> = ({ selected, product: { count, price, price_currency_code, discountRate }, onClick }) => {
  return (
    <div
      onClick={onClick}
      className="relative w-full py-3 px-8 mt-2 rounded-lg text-white flex flex-column align-center justify-center gap-3"
      style={{ border: `2px solid ${selected ? '#FFF' : 'transparent'}`, backgroundColor: 'rgba(255, 255, 255, 0.30)' }}
    >
      <div className="text-title3">{brsc.times.replace('$param', count)}</div>
      <div className="flex flex-row rounded-xl gap-1 py-1 px-4" style={{ backgroundColor: 'rgba(0, 0, 0, 0.50)' }}>
        <div className="text-title3">{`${PageShop.numberToCurrencyString(price, price_currency_code)}`}</div>
      </div>
      {discountRate && (
        <div className="absolute top-2 right-2 text-white py-1 px-2 rounded-md text-title4" style={{ backgroundColor: 'rgba(255, 67, 144, 0.50)' }}>
          {discountRate}%
        </div>
      )}
    </div>
  );
};

export default TimeBuffPurchaseModal;
