import { PageLayout } from 'components/common';
import Button from 'components/common/Button';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const TimeBuffPurchaseComplete: React.FC = () => {
  const navigate = useNavigate();
  const [fadeIn, setFadeIn] = useState<boolean>(false);

  useEffect(() => {
    setFadeIn(true);
  }, []);

  const handleUseClick = () => {
    closeAll();
    setTimeout(() => {
      Transition.showPageWithoutHide(Def.BOTTOM_SHEET_TIME_BUFF_FILTER);
    }, 500);
  };

  const handleLaterClick = () => closeAll();

  const closeAll = () => {
    navigate('/', { replace: true });
    Transition.backUntilNamed(Def.TAB_HOME);
  };

  return (
    <PageLayout overlay opacity={0.85}>
      <div className="w-full h-full flex flex-column justify-between">
        <div></div>
        <div className="w-full flex flex-column align-center justify-center px-5">
          <img className="w-full px-7 transition-opacity duration-500" style={{ opacity: fadeIn ? 1 : 0 }} src="/imgweb/sale/infinity_silver_pink.png" alt="" />
          <div className="text-white text-title1 mt-3">{brsc.prchase_complete_title}</div>
        </div>
        <div className="w-full flex flex-column gap-4 px-5 pb-5">
          <Button className="w-full" style={{ text: brsc.prchase_complete_btn, color: 'white', fontColor: 'text-700' }} onClick={handleUseClick} />
          <Button className="w-full" style={{ text: brsc.later, type: 'text', fontColor: 'white' }} onClick={handleLaterClick} />
        </div>
      </div>
    </PageLayout>
  );
};

export default TimeBuffPurchaseComplete;
